<template>
  <v-dialog v-model="dialog" width="500">
    <v-card v-if="id != ''">
      <v-toolbar dense :color="hexTints(area.cor,0.95)">
        <v-btn class="ms-0 me-2" icon dark small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ms-1 ps-0 fs-13pt f-lato fw-600">Editar Tópico Gerador</v-toolbar-title>
      </v-toolbar>
      <v-card class="my-2 mx-2 p-2" :color="hexTints(area.cor,0.97)">
        <p class="m-0 p-0 mb-1 fs-9pt fw-400">
          <span>Digite o texto do TÓPICO GERADOR</span>
          <span class="ms-2 fs-8pt fw-800">ID:</span>
          <span class="ms-1 fs-8pt fw-600 f-lato">{{id}}</span>
        </p>
        <v-textarea rows="2" solo dense hide-details class="fs-11pt f-lato text-justify line-height-0" v-model="texto"></v-textarea>
      </v-card>
      <v-card-text class="mt-3 mb-0 py-0">
        Deseja realmente <span class="fw-700">ALTERAR</span> este tópico gerador?
      </v-card-text>
      <v-divider class="mb-0"></v-divider>
      <v-card-actions class="mt-1 py-1">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.I" text @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.D" text @click="confirm">
          Alterar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "promptdialogupdate",
  props: {
    area: { default: null, type: Object }
  },
  components: { },
  data() {
    return {
      dialog: false,
      texto: "",
      id: "",
    }
  },
  computed: {},
  watch: {
  },
  mounted: function() {
    var self = this;
    //this.log("Mounted");
  },

  methods:{

    show(item) {
      this.dialog = true;
      this.texto = item.texto;
      this.id = item.id;
    },

    confirm() {
      console.log("confirm");
      this.dialog = false;
      this.$emit('edititem',{ id: this.id, texto: this.texto });
    }
  }
}
</script>

<style scoped>

</style>
